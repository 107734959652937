import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'

//  { areaCodes: null, priority: 0, dialCode: "93", iso2: "af", name: "Afghanistan" }
function update_country_code_input(data) {
  document.querySelector('input[name="user[country_code]"]').value = data['dialCode']
}

Array
  .from(document.querySelectorAll('[data-plugin="intl-tel-input"]'))
  .forEach(function(input) {

    // https://intl-tel-input.com/storybook/?path=/docs/intltelinput--showflags
    // https://www.twilio.com/en-us/blog/international-phone-number-input-html-javascript
    const iti = intlTelInput(input, {
      allowDropdown: true,
      // https://intl-tel-input.com/storybook/?path=/docs/intltelinput--allowdropdown

      countrySearch: true,

      excludeCountries: [],
      // https://intl-tel-input.com/storybook/?path=/docs/intltelinput--excludecountries

      fixDropdownWidth: false,
      // https://intl-tel-input.com/storybook/?path=/docs/intltelinput--fixdropdownwidth

      formatAsYouType: true,

      formatOnDisplay: true,
      // https://intl-tel-input.com/storybook/?path=/docs/intltelinput--initialvalue

      initialCountry: 'us',
      // https://intl-tel-input.com/storybook/?path=/docs/intltelinput--initialcountry

      nationalMode: true,
      // https://intl-tel-input.com/storybook/?path=/docs/intltelinput--initialvalue

      placeholderNumberType: 'MOBILE',
      // https://intl-tel-input.com/storybook/?path=/docs/intltelinput--placeholdernumbertype

      preferredCountries: ['us', 'ca'],
      // https://intl-tel-input.com/storybook/?path=/docs/intltelinput--preferredcountries

      showFlags: true,
      // https://intl-tel-input.com/storybook/?path=/docs/intltelinput--showflags

      showSelectedDialCode: true,
      // https://intl-tel-input.com/storybook/?path=/docs/intltelinput--showselecteddialcode

      strictMode: false,

      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/20.3.0/js/utils.js'
      // https://github.com/jackocnr/intl-tel-input?tab=readme-ov-file#utilities-script
    })

    // https://github.com/jackocnr/intl-tel-input?tab=readme-ov-file#events
    input.addEventListener("countrychange", function() {
      // do something with iti.getSelectedCountryData()
      console.log('Country changed')
      console.log('iti.getSelectedCountryData()', iti.getSelectedCountryData())
      update_country_code_input(iti.getSelectedCountryData())
    })

    input.addEventListener('change', function(event) {
      console.log('Phone changed')
      console.log('iti.getNumber()', iti.getNumber())
      console.log('iti.getNumber(E164)', iti.getNumber(intlTelInputUtils.numberFormat.E164))
    })
  })
